@tailwind base;
@tailwind components;
/* @tailwind utilities; */
@tailwind /* @import '../node_modules/flatpickr/dist/flatpickr.min.css'; */
@import 'react-date-range/dist/styles.css';
@import "react-date-range/dist/theme/default.css";
@import "react-datepicker/dist/react-datepicker.css";

body {
  font-family: var(--airco2-font);
}

@font-face {
  font-family: "ITCAvantGardeGoticBold";
  src: url("./fonts/ITCAvantGardeBold.woff") format("woff");
}

@font-face {
  font-family: "ITCAvantGardeStd";
  src: url("./fonts/ITCAvantGardeStd-Bk.woff") format("woff");
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-Regular.woff") format("woff");
}

@font-face {
  font-family: "Xunta";
  src: url("./fonts/XuntaSans-Regular.woff") format("woff");
}


.font-gp {
  font-family: "ITCAvantGardeStd", sans-serif !important;
}

.text-xl-gp {
  font-size: 1.25rem;
}

.text-lg-gp {
  font-size: 1.125rem;
  line-height: 1.5;
}

.font-gp a {
  color: var(--gp-green-color);
}

.font-gp a:hover {
  text-decoration: underline;
}

.gp-image-cert {
  max-width: 200px;
  width: calc(100% / 4);
}

@media (min-width: 426px) {
}

.gp-container-bolsas {
  flex-direction: column-reverse;
}

.gp-video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.gp-video {
  position: absolute;
  width: 100%;
  height: 100%;
}

@media (min-width: 426px) {
  .gp-video {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}

.text-gp-darkgrey {
  color: var(--gp-darkGrey-color);
  font-weight: bold;
}

.text-gp-lightgrey {
  color: black;
}

.text-gp-green {
  color: var(--gp-green-color);
}

:root {
  --airco2-grey-color: #878787;
  --airco2-green-color: #8dd4bd;
  --airco2-dark-green-color: #155632;
  --airco2-light-green-color: rgba(141, 212, 189, 0.3);
  --airco2-orange-color: #f19b7f;
  --gp-darkGrey-color: #2e2d2c;
  --gp-lightGrey-color: #636569;
  --gp-green-color: #55b947;
  --airco2-neutro-color: #f5f8fa;
  --airco2-font: "openLight";
  --airco2-grey-text: #64748b;
  --airco2-greenSidebar-color: #8dd4bd;
  --airco2-bg-landing: #f4fbf9;
  --airco2-scope1: #f28c6a;
  --airco2-scope1-light: #ffd9b3;
  --airco2-scope2: #ffcc33;
  --airco2-scope2-light: #ffdd66;
  --airco2-scopes1and2: #ff9955;
  --airco2-scopes1and2-light: #ffe0cc;
  --airco2-scopes1and2-evolution: #fadbbd;
  --airco2-scopes1and2-evolution-light: #fff2e6;

  --airco2-scope3: #6699cc;
  --airco2-scope3-light: #b3d1ff;
  --airco2-scope3-evolution: #90a4ae;
  --airco2-scope3-evolution-light: #e9f0f8;
}

.bg-orange {
  background-color: var(--airco2-orange-color);
}

.bg-gris {
  background-color: var(--airco2-grey-color) !important;
}

.bg-gris-semitr {
  background-color: rgba(135, 135, 135, 0.5);
}

.bg-gris-tr {
  background-color: rgba(135, 135, 135, 0.05);
}

.bg-green-semitr {
  background-color: var(--airco2-light-green-color) !important;
}

.bg-landing {
  background-color: var(--airco2-bg-landing) !important;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.text-airco2-general {
  color: var(--airco2-grey-color);
  font-family: "openLight";
}

.text-airco2-general-grey {
  color: var(--airco2-grey-color);
}
.text-airco2-principal {
  color: var(--airco2-green-color);
}

.text-airco2-secondary {
  color: var(--airco2-orange-color);
}

.text-airco2-home-title {
  color: var(--airco2-green-color);
  font-size: 30px;
  font-family: "openLight";
}

.text-airco2-filter-content {
  color: var(--airco2-grey-color) !important;
  font-size: 12px;
  font-family: "openReg";
}

a.text-airco2-principal:hover,
a.text-airco2-filter-content:hover,
a.text-airco2-general:hover {
  color: var(--airco2-orange-color) !important;
}

a.text-coolberries:hover {
  color: #222d70 !important;
}

a.text-coolberries-general:hover {
  color: #ea5a0b !important;
}

.dark-link{
    color: var(--airco2-dark-green-color) !important;
}
.dark-link:hover{
  color: var(--airco2-grey-color) !important;
}
.text-airco2-landing {
  color: white;
  font-family: "openReg";
  text-shadow: 1px 2px 1px #878787;
}

.text-airco2-concellos {
  color: #545a6d;
  font-family: "openLight";
}

.text-airco2-title-concellos {
  color: white;
  font-family: "openReg";
}

.text-airco2-title-landing {
  font-family: "openReg";
  color: var(--airco2-grey-color);
  text-shadow: 2px 2px 2px #a2a2a2;
  /* width: 100%!important; */
}

/* .text-sub{
  display: none!important;
} */

.foot {
  background-color: #56596e;
  color: rgba(255, 255, 255, 0.5);
}

.foota {
  background-color: #56596e;
  color: rgba(255, 255, 255, 0.3);
}

.footnew {
  text-decoration: none;
}

.footnew:hover,
a:active {
  color: var(--airco2-orange-color);
  text-decoration: none;
}

.sec-1 {
  background-color: #270376;
  color: #f8f3ea;
}

/* Show in Large desktops and laptops */
@media (min-width: 1200px) {
  .rowback {
    background: url("./images/bosque_fondo.svg");
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
  }

  .rowback2 {
    background: url("./images/bosque_fondo2.svg");
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
  }

  .rowback3 {
    background: url("./images/bosque_fondo3.svg");
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
  }

  .rowback4 {
    background: url("./images/bosque_fondo4.svg");
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
  }

  @media (min-width: 1025px) {
    .navMargin {
      margin-right: 3rem;
      margin-left: 3rem;
    }
  }
}

/* .ilustra01 {
  background: url('./images/ilustra01.svg');
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center;
} */

.ilustra {
  bottom: -90px;
  left: -25px;
}

@media (max-width: 768px) {
  .ilustra {
    bottom: -20px;
  }
}

/*Hide in Other Small Devices */

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  .rowback {
    background: url("./images/bosque_fondo.svg");
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
  }

  .rowback2 {
    background: url("./images/bosque_fondo2.svg");
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
  }

  .rowback3 {
    background: url("./images/bosque_fondo3.svg");
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
  }

  .rowback4 {
    background: url("./images/bosque_fondo4.svg");
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .rowback {
    background: url("./images/bosque_fondo.svg");
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
  }

  .rowback2 {
    background: url("./images/bosque_fondo2.svg");
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
  }

  .rowback3 {
    background: url("./images/bosque_fondo3.svg");
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
  }

  .rowback4 {
    background: url("./images/bosque_fondo4.svg");
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .rowback {
    background-image: none;
  }

  .rowback2 {
    background-image: none;
  }

  .rowback3 {
    background-image: none;
  }

  .rowback4 {
    background-image: none;
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
  .rowback {
    background-image: none;
  }
}

.sec-grey-background {
  background-color: #56596e;
}

.sec-lila-background {
  background-color: #270376;
}

.sec-neutro-background {
  background-color: var(--airco2-neutro-color);
}

.sec-green-background {
  background-color: var(--airco2-green-color) !important;
}

.sec-dark-green-background {
  background-color: var(--airco2-dark-green-color) !important;
}

.sec-green-background-tr {
  background-color: var(--airco2-green-color) !important;
  opacity: 0.5;
  font-weight: bold;
}

@keyframes fadeEffect {
  0% {
    background-color: var(--airco2-orange-color);
    opacity: 0.5;
    color: black;
  }

  100% {
    background-color: transparent;
    color: black;
  }
}

.sec-green-background-tr2 {
  animation: fadeEffect 6s ease-out forwards;
}

.sec-sidebar-background {
  background-color: var(--airco2-greenSidebar-color) !important;
}

.sec-orange-background {
  background-color: var(--airco2-orange-color);
}

.sec-blue-background {
  background-color: #545a6d !important;
}

.sec-beige-text {
  color: #f8f3ea;
}

.sec-green-text {
  color: var(--airco2-green-color);
}

.sec-white-text {
  color: #ffffff;
}

.sec-grey-text {
  color: var(--airco2-grey-color);
}

.sec-blue-text {
  color: #545a6d !important;
}

.sec-f-text {
  color: #a6a5b1;
}

.sec-orange-text {
  color: #f39c7e !important;
}

.border-blue {
  border-color: #545a6d;
}

.sec-111 {
  width: 300px;
}

.sec-12 {
  background-color: var(--airco2-green-color);
  color: #56596e;
}

.sec-121 {
  height: 700px;
}

.sec-122 {
  color: #f8f3ea;
}

.sec-beige {
  background-color: var(--airco2-neutro-color);
  color: #56596e;
}

.sec-14 {
  color: var(--airco2-green-color);
}

.enlace {
  background-color: var(--airco2-green-color);
  color: #56596e;
  border-radius: 50px;
  font-weight: 700;
  font-size: xx-large;
}

.border-10 {
  border-style: solid;
  border-width: 10px;
  border-radius: 50px;
  border-color: #56596e;
}

.border-11 {
  border-style: solid;
  border-width: 10px;
  border-radius: 50px;
  background-color: #56596e;
  border-color: #56596e;
}

.border-12 {
  border-radius: 50px;
}

.sec-15 {
  background-color: #56596e;
  color: #f39d7c;
}

.contador {
  background-color: transparent;
  color: white;

  min-width: 5.7rem;
  font-weight: 800;
  min-height: 5rem;
  text-align: center;
  padding-top: 1.3rem;
  padding-bottom: 0.1rem;
  border-radius: 25%;
  font-size: 1.8rem;
}

.containerContador {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 4rem;
  margin-left: 10%;
}

.textocontador {
  font-size: 1rem;
  font-weight: 100;
  margin-bottom: 0.3rem;
}

.separacion {
  margin-top: 25px;
  width: 1px;
  height: 50px;
  background-color: #fff;
  opacity: 45%;
}

.images {
  display: inline;
  margin: 10px;
  padding: 0px;
  vertical-align: middle;
  width: 35px;
}

.images2 {
  display: inline;
  margin-right: 10px;
  padding: 0px;
  vertical-align: middle;
  width: 20px;
}

#content {
  display: block;
  margin: 0px;
  padding: 0px;
  position: relative;

  height: auto;
  max-width: auto;
  overflow-y: hidden;
  overflow-x: auto;
  word-wrap: normal;
}

.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}

.active.fade-left {
  animation: fade-left 1s ease-in;
}

.active.fade-right {
  animation: fade-right 1s ease-in;
}

.world {
  margin-left: 50px;
  margin-right: 50px;
}

.flecha1 {
  position: absolute;
  top: 9%;

  transform: rotate(45deg);
}

.flecha2 {
  position: absolute;
  top: 90%;
  left: 45%;
}

@keyframes fade-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.carousel {
  margin-top: 10px;
}

.carousel-inner {
  height: 220px;
  width: 400px;
}

.carousel-caption {
  color: #fff;
  top: 500%;
}

/*QUIZ*/
.correct,
.correct:hover {
  background-color: #2f922f !important;
  border: 5px solid #2f922f !important;
  color: white !important;
}

.no-correct,
.no-correct:hover {
  background-color: #ff3333 !important;
  border: 5px solid #ff3333 !important;
  color: white !important;
}

.btn-answer {
  width: 100%;
  color: #000000;
  background-color: #f8f3ea;
  border-radius: 15px;
  display: flex;
  padding: 5px;

  border: 5px solid var(--airco2-green-color);
  cursor: pointer;
  display: inline-block;
  margin-bottom: 5px;
}

.img-question {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 200px;
}

.imgs {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
}

#lead-form input {
  color: var(--airco2-green-color);
}

#lead-form input:focus {
  outline: none !important;
  border: 1px solid #f39d7c;
  box-shadow: 0 0 0 #f39d7c;
}

.text-red {
  color: rgb(239 68 68);
}

.bg-red-500 {
  background-color: rgb(239 68 68);
}

.text-1xs {
  font-size: 0.65rem;
}

/* CONTREEBUYE */
.text-6xl {
  font-size: 3rem;
}

.text-5xl {
  font-size: 2.8rem;
}

/* @media (min-width: 880px) {
  #header-text-resp{
    display: none;
  }
  #header-text{
    display: block;
  }
}

 @media (min-width: px) and (max-width: 1199px)  {
  #header-text{
    display: none;
  }
  #header-text-resp{
    display: block;
  }
} */

/* @media (min-width: 481px) {
  #header-text-resp{
    display: none;
  }
} */
@media (min-width: 951px) {
  #header-text-resp {
    display: none;
  }

  #header-text {
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 950px) {
  #header-text-resp {
    display: block;
  }

  #header-text {
    display: none;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  #header-text-resp {
    display: none;
  }

  #header-text {
    display: block;
  }
}

@media (max-width: 480px) {
  #header-text {
    display: none;
  }

  #portada {
    filter: brightness(0.75);
  }
}

@media (max-width: 767px) {
  #img-hojas {
    display: none;
  }
}

.ticket {
  position: relative;
  height: 210px;
  width: 282px;
  border: 2px solid grey;
  border-radius: 0.5em;
  background: white;
  color: black;
  display: inline-block;
  padding: 1em 1.5em;
  margin-top: 2em;
}

.ticket:before,
.ticket:after {
  height: 200px;
  width: 45px;
  content: "";
  position: absolute;
  top: 3em;
  height: 1.2em;
  width: 0.6em;
  border: 2px solid grey;
}

.ticket:before {
  left: -2px;
  border-radius: 0 1em 1em 0;
  border-left-color: #f8f3ea;
  background-color: #f8f3ea;
}

.ticket:after {
  right: -2px;
  border-radius: 1em 0 0 1em;
  border-right-color: #f8f3ea;
  background-color: #f8f3ea;
}

#buble {
  position: relative;
  height: 150px;
  overflow: hidden;
  background: var(--airco2-green-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

#buble h2 {
  font-family: consolas;
  font-size: 10em;
  color: #333;
}

#buble span {
  position: absolute;
  bottom: 150px;
  background: transparent;
  border-radius: 50%;
  pointer-events: none;
  box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.5);
  animation: animate 4s linear infinite;
}

@keyframes animate {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }

  100% {
    transform: translateY(500%);
    opacity: 1;
  }
}

.Datepick {
  text-align: center;
  height: 100vh;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.input-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.input-container .react-datepicker-wrapper {
  margin: 12px 0;
  width: 100%;
}

.input-container .react-datepicker-wrapper input {
  padding: 4px 12px;
}

#date-filter {
  font-size: 12px;
  border: 1px solid hsl(0, 0%, 80%) !important;
  padding: 3px 10px !important;
}

.react-datepicker__close-icon::after,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-range{
  background-color: var(--airco2-green-color);
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--in-range:hover{
  background-color: var(--airco2-orange-color);
}

.react-datepicker__day--in-selecting-range{
  background-color: var(--airco2-grey-color);
}
.react-datepicker__day--in-selecting-range:hover{
  background-color: var(--airco2-light-green-color);
}




.summary {
  font-size: 18px;
}

.text48 {
  font-size: 3.2rem;
}

.text35 {
  font-size: 2.5rem;
}

.text30 {
  font-size: 2.2rem;
}

.text28 {
  font-size: 2rem;
}

.text24 {
  font-size: 1.7rem;
}

.text18 {
  font-size: 1.5rem;
}

.text16 {
  font-size: 1.3rem;
}

.text14 {
  font-size: 1.1rem;
}

.text12 {
  font-size: 0.9rem;
}

.text10 {
  font-size: 0.8rem;
}

/* legaltitle */
.legaltitle {
  font-family: openBold;
  text-decoration: underline;
  margin-top: 25px;
}

@media (max-width: 640px) {
  .sliderbtn {
    text-align: center;
  }
}

html.wait,
html.wait * {
  cursor: wait !important;
}

@font-face {
  font-family: "openBold";
  src: url("./fonts/openSans/OpenSans-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "openReg";
  src: url("./fonts/openSans/OpenSans-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "openLight";
  src: url("./fonts/openSans/OpenSans-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: "openSemi";
  src: url("./fonts/openSans/OpenSans-Semibold.ttf");
  font-display: swap;
}

.linkhover:hover {
  color: var(--airco2-green-color);
}

.linkNav:hover {
  color: var(--airco2-orange-color);
}

/* start flexing */
.flex-outer div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.flex-outer > div > label:first-child,
.flex-outer div p {
  flex: 1 0 120px;
  max-width: 220px;
}

.flex-outer > div > label:first-child + * {
  flex: 1 0 220px;
}

.flex-outer > div:not(:last-child) {
  margin-bottom: 20px;
}

.excel-select {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

.excel-select p {
  display: table;
}

.excel-select p .align-center {
  display: table-cell;
  vertical-align: middle;
}

.excel-ex {
  display: none !important;
}

@media (max-width: 480px) {
  .excel-select {
    grid-template-columns: repeat(1, 1fr);
    gap: 0.5rem;
  }

  .separator {
    margin-bottom: 1rem;
    border-bottom: 1px solid #dee2e6;
  }

  .excel-header {
    display: none !important;
  }

  .excel-ex {
    display: inline-block !important;
  }
}

.table-responsive > .table-bordered {
  border: 4px solid #dee2e6;
}

.note-font {
  font-size: 0.7rem;
}

/* IMPORTER CSV */
.box-bank {
  display: flex;
  flex-direction: column;
  -moz-box-align: center;
  align-items: center;
  background-color: white;
  border: 2px solid white;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 4px;
  cursor: pointer;
  height: 115px;
  position: relative;
  width: 115px;
}

.selected-box-bank {
  border: 2px solid var(--airco2-green-color);
}

.selected-box-bank-orange {
  border: 2px solid var(--airco2-orange-color);
}

.container-img-bank {
  -moz-box-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  -moz-box-flex: 1;
  flex-grow: 1;
  -moz-box-pack: center;
  justify-content: center;
}

.img-bank {
  height: 70px;
}

.container-bank-list {
  display: grid;
  gap: 1.5rem;
  /* grid-template-columns: repeat(9, minmax(0px, 1fr)); */
}

.bank-instrucction-list {
  list-style-type: none;
  counter-reset: lista-personalizada;
  margin: 0;
}

.bank-instrucction-list li {
  position: relative;
  /* font-weight: bold; */
  font-size: 1rem;
  line-height: 1.95rem;
  padding: 4px 0 10px 50px;
  margin-bottom: 0.625rem;
  background: #e3e3e3;
}

.bank-instrucction-list li:before {
  content: counter(lista-personalizada);
  counter-increment: lista-personalizada;
  position: absolute;
  left: 0;
  top: 0;
  font-weight: bold;
  font-size: 2.3rem;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  background: var(--airco2-green-color);
  color: white;
  height: 2.813rem;
}

.bank-instrucction-list img {
  margin-bottom: 0.625rem;
}

.bank-instrucction-list a {
  color: var(--airco2-green-color);
  font-weight: bold;
}

.bank-instrucction-list a:hover {
  text-decoration: underline;
}

#container-instruccions .accordion-button {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 0.938rem;
  font-weight: bold;
  background-color: var(--airco2-green-color);
  color: white;
  padding: 0.938rem;
}

#container-instruccions .accordion-item {
  background-color: white;
  border: 0;
}

.progress-bar {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  background-color: rgb(99 102 241);
}

.progress {
  border-radius: 0px !important;
  border: none;
}

.grey-input {
  border-color: rgb(226 232 240) !important;
}

.btn-xs {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.btn-xs {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

@media (max-width: 640px) {
  .btn-xs {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

@media (max-width: 1279px) and (min-width: 768px) {
  .flexFilterUp {
    display: inline-flex;
  }

  .filterBtnMr {
    margin-right: 10px;
  }
}

@media (max-width: 767px) {
  .filterBtnsRow {
    /* grid-rows-2 items-center justify-between */
    grid-template-rows: repeat(2, minmax(0, 1fr));
    align-items: center;
    justify-content: space-between;
  }

  .filterBtnMt {
    margin-top: 10px;
  }
}

@media (max-width: 1279px) and (min-width: 768px) {
  .flexFilterUp {
    display: inline-flex;
  }

  .filterBtnMr {
    margin-right: 10px;
  }
}

@media (max-width: 767px) {
  .filterBtnsRow {
    /* grid-rows-2 items-center justify-between */
    grid-template-rows: repeat(2, minmax(0, 1fr));
    align-items: center;
    justify-content: space-between;
  }

  .filterBtnMt {
    margin-top: 10px;
  }
}

.porcentajeTree {
  width: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 240px;
  margin-top: 12px;
  font-size: 14px;
  font-weight: 600;
  color: #816954;
  opacity: 0.8;
}

.container-arbolMontes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1rem;
}

.arbolYprogress {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 1rem;
}

.card-container {
  min-width: 22rem;
  max-width: 22rem;
  margin-top: 2%;
  margin-left: 2%;
  margin-right: 2%;
}

.listArbol {
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
}

.backgroundTree {
  position: relative;
  min-height: 320px;
  max-height: 320px;
}

.backgroundTree .slope {
  position: absolute;
  bottom: 100%;
}

.tree {
  /* 			background: ; */
  width: 2px;
  /* height: 200px; */
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-bottom: 300px solid #816954;
  position: absolute;
  left: 50%;

  transform-origin: 50% 300px;
  top: 50px;
  margin-left: -8px;
}

.branch {
  position: absolute;
  width: 150px;
  height: 40px;
  border-bottom: 5px solid #816954;
}

.branch:before,
.branch:after {
  content: "";
  position: absolute;
  width: inherit;
  height: inherit;
  border: inherit;
  border-radius: inherit;
  z-index: 0;
}

.branch:before {
  transform: rotate(1deg);
}

.branch:after {
  transform: rotate(2deg);
}

.branch.left,
.branch.left:before,
.branch.left:after {
  transform-origin: 0px 0px;
}

.branch.right,
.branch.right:before,
.branch.right:after {
  transform-origin: 100% 0px;
}

.branch.left {
  border-bottom-left-radius: 100% 100%;
  transform-origin: 100% 100%;
}

.branch.right {
  border-bottom-right-radius: 100% 100%;
  transform-origin: 0% 100%;
  margin-left: 1px;
}

.tree > .branch.left {
  transform: rotate(5deg);
}

.tree > .branch.right {
  transform: rotate(-5deg);
}

.leaf {
  position: absolute;
  width: 23px;
  height: 23px;
  border-radius: 0px 100%;
  z-index: 1;
  transform-origin: 0px 0px;
}

.branch .branch:after {
  content: none;
}

.tree > .leaf1 {
  width: 15px;
  height: 15px;
  transform: rotate(-150deg);
}

.tree > .leaf2 {
  width: 15px;
  height: 15px;
  transform: rotate(-100deg);
}

.branch1 {
  top: 190px;
  right: 0;
}

.branch1 .branch-inner1 {
  width: 60px;
  height: 40px;
  transform: rotate(35deg);
  border-bottom-left-radius: 100% 70%;
  left: 50px;
  top: 0px;
  /* border-width: 5px;	 */
}

.branch1 .branch-inner2 {
  width: 50px;
  height: 20px;
  transform: rotate(-40deg);
  border-bottom-left-radius: 100% 70%;
  left: 26px;
  top: 13px;
  /* border-width: 4px;	 */
}

.branch1 .branch-inner3 {
  width: 20px;
  height: 10px;
  transform: rotate(40deg);
  border-bottom-left-radius: 100% 70%;
  left: 16px;
  top: 15px;
  /* border-width: 3px;	 */
}

.branch1 .branch-inner1 .leaf1 {
  transform: rotate(-150deg);
  top: 38px;
  left: 40px;
}

.branch1 .branch-inner1 .leaf2 {
  width: 17px;
  height: 17px;
  transform: rotate(110deg);
  top: 38px;
  left: 20px;
}

.branch1 .branch-inner1 .leaf3 {
  width: 17px;
  height: 17px;
  transform: rotate(-150deg);
  top: 30px;
  left: 14px;
}

.branch1 .branch-inner2 .leaf1 {
  transform: rotate(80deg);
  left: 40px;
  top: 24px;
}

.branch1 .branch-inner2 .leaf2 {
  width: 20px;
  height: 20px;
  transform: rotate(-160deg);
  left: 30px;
  top: 20px;
}

.branch1 .branch-inner2 .leaf3 {
  width: 17px;
  height: 17px;
  transform: rotate(100deg);
  left: 10px;
  top: 18px;
}

.branch1 .branch-inner3 .leaf1 {
  width: 15px;
  height: 15px;
  transform: rotate(-150deg);
  left: 18px;
  top: 10px;
}

.branch1 .branch-inner3 .leaf2 {
  width: 15px;
  height: 15px;
  transform: rotate(130deg);
  left: 9px;
  top: 12px;
}

.branch1 > .flower1 {
  right: 100%;
  bottom: 100%;
  width: 30px;
  height: 30px;
  margin-right: -15px;
  margin-bottom: -15px;
}

.branch1 .branch-inner1 .flower1 {
  width: 18px;
  height: 18px;

  top: 5px;
  left: -12px;
  transform: rotate(-25deg);
}

.branch1 .branch-inner2 .flower1 {
  width: 20px;
  height: 20px;

  top: -25%;
  left: 0%;
  transform: rotate(-55deg);
}

.branch2 {
  height: 60px;
  top: 130px;
  left: 0;
}

.branch2 .branch-inner1 {
  width: 60px;
  height: 70px;
  transform: rotate(90deg);
  border-bottom-left-radius: 100% 70%;
  left: -26px;
  top: -13px;
  /* border-width: 5px;	 */
}

.branch2 .branch-inner2 {
  width: 60px;
  height: 80px;
  transform: rotate(60deg);
  border-bottom-right-radius: 100% 70%;
  left: 55px;
  top: -26px;
  /* border-width: 4px;	 */
}

.branch2 .branch-inner3 {
  width: 40px;
  height: 30px;
  transform: rotate(30deg);
  border-bottom-right-radius: 100% 100%;
  left: 110px;
  top: 10px;
  /* border-width: 3px;	 */
}

.branch2 .branch-inner4 {
  width: 20px;
  height: 15px;
  transform: rotate(180deg);
  border-bottom-left-radius: 100% 100%;
  left: 0px;
  top: 8px;
  /* border-width: 2px;	 */
}

.branch2 .branch-inner1 .leaf1 {
  transform: rotate(120deg);
  top: 74px;
  left: 40px;
}

.branch2 .branch-inner1 .leaf2 {
  transform: rotate(220deg);
  top: 63px;
  left: 30px;
  width: 20px;
  height: 20px;
}

.branch2 .branch-inner1 .leaf3 {
  transform: rotate(150deg);
  top: 60px;
  left: 14px;
  width: 17px;
  height: 17px;
}

.branch2 .branch-inner2 .leaf1 {
  transform: rotate(-20deg);
  top: 84px;
  left: 10px;
}

.branch2 .branch-inner2 .leaf2 {
  transform: rotate(-120deg);
  top: 73px;
  left: 25px;
  width: 20px;
  height: 20px;
}

.branch2 .branch-inner2 .leaf3 {
  transform: rotate(-40deg);
  top: 65px;
  left: 44px;
  width: 17px;
  height: 17px;
}

.branch2 .branch-inner3 > .leaf1 {
  transform: rotate(0deg);
  top: 32px;
  left: 10px;
  width: 20px;
  height: 20px;
}

.branch2 .branch-inner3 .leaf2 {
  transform: rotate(-120deg);
  top: 25px;
  left: 20px;
  width: 20px;
  height: 20px;
}

.branch2 .branch-inner3 .leaf3 {
  transform: rotate(-50deg);
  top: 14px;
  left: 37px;
  width: 17px;
  height: 17px;
}

.branch2 .branch-inner4 > .leaf1 {
  transform: rotate(150deg);
  top: 14px;
  left: 6px;
  width: 14px;
  height: 14px;
}

.branch2 > .flower1 {
  width: 25px;
  height: 25px;
  right: -12px;
  top: -8px;
}

.branch2 .branch-inner1 .flower1 {
  width: 25px;
  height: 25px;
  left: -10px;
  top: 25px;
}

.branch2 .branch-inner2 .flower1 {
  transform: rotate(10deg);
  width: 20px;
  height: 20px;
  right: -5px;
  bottom: 30px;
}

.branch2 .branch-inner4 .flower1 {
  width: 20px;
  height: 20px;
  left: -10px;
  top: -10px;
}

.branch2 .branch-inner3 > .flower1 {
  transform: rotate(10deg);
  width: 15px;
  height: 15px;
  right: -5px;
  top: -5px;
}

.branch3 {
  top: 70px;
  right: 0;
  width: 160px;
  height: 70px;
}

.branch3 .branch-inner1 {
  width: 60px;
  height: 40px;
  transform: rotate(-80deg);
  border-bottom-right-radius: 100% 100%;
  left: 70px;
  top: 15px;
  /* border-width: 4px;	 */
}

.branch3 .branch-inner2 {
  width: 60px;
  height: 60px;
  transform: rotate(-40deg);
  border-bottom-left-radius: 100% 70%;
  left: 36px;
  top: 5px;
  /* border-width: 4px;	 */
}

.branch3 .branch-inner1 .leaf1 {
  transform: rotate(-30deg);
  top: 44px;
  left: 15px;
}

.branch3 .branch-inner1 .leaf2 {
  width: 17px;
  height: 17px;
  transform: rotate(-95deg);
  top: 35px;
  left: 23px;
}

.branch3 .branch-inner1 .leaf3 {
  width: 17px;
  height: 17px;
  transform: rotate(-50deg);
  top: 32px;
  left: 44px;
}

.branch3 .branch-inner2 .leaf1 {
  transform: rotate(80deg);
  left: 50px;
  top: 64px;
}

.branch3 .branch-inner2 .leaf2 {
  width: 20px;
  height: 20px;
  transform: rotate(-160deg);
  left: 35px;
  top: 56px;
}

.branch3 .branch-inner2 .leaf3 {
  width: 17px;
  height: 17px;
  transform: rotate(100deg);
  left: 16px;
  top: 50px;
}

.branch3 > .leaf1 {
  right: 20px;
  transform: rotate(200deg);
  bottom: -17px;
  width: 18px;
  height: 18px;
}

.branch3 > .leaf2 {
  right: 110px;
  transform: rotate(220deg);
  bottom: 10px;
  width: 18px;
  height: 18px;
}

.branch3 .flower.petal5 {
  right: 100%;
  bottom: 100%;
  width: 30px;
  height: 30px;
  margin-right: -15px;
  margin-bottom: -15px;
}

.branch3 .branch-inner1 .heart {
  width: 18px;
  height: 18px;

  top: 0px;
  left: 50px;
  transform: rotate(35deg);
}

.branch3 .branch-inner2 .tulip {
  width: 20px;
  height: 20px;

  top: 20px;
  left: 0px;
  transform: rotate(-25deg);
}

.branch4 {
  width: 120px;
  height: 40px;
  top: 50px;
  left: 0;
}

.branch4 .branch-inner1 {
  width: 50px;
  height: 60px;
  transform: rotate(90deg);
  border-bottom-left-radius: 100% 70%;
  left: -26px;
  top: -25px;
  /* border-width: 5px;	 */
}

.branch4 .branch-inner2 {
  width: 50px;
  height: 70px;
  transform: rotate(60deg);
  border-bottom-right-radius: 100% 70%;
  left: 55px;
  top: -36px;
  /* border-width: 4px;	 */
}

.branch4 .branch-inner1 .leaf1 {
  transform: rotate(120deg);
  top: 65px;
  left: 42px;
}

.branch4 .branch-inner1 .leaf2 {
  transform: rotate(220deg);
  top: 57px;
  left: 30px;
  width: 20px;
  height: 20px;
}

.branch4 .branch-inner1 .leaf3 {
  transform: rotate(150deg);
  top: 54px;
  left: 14px;
  width: 17px;
  height: 17px;
}

.branch4 .branch-inner2 .leaf1 {
  transform: rotate(-20deg);
  top: 74px;
  left: 10px;
}

.branch4 .branch-inner2 .leaf2 {
  transform: rotate(-120deg);
  top: 65px;
  left: 20px;
  width: 20px;
  height: 20px;
}

.branch4 .branch-inner2 .leaf3 {
  transform: rotate(-40deg);
  top: 60px;
  left: 35px;
  width: 17px;
  height: 17px;
}

.branch4 > .flower1 {
  width: 25px;
  height: 25px;
  right: -12px;
  top: -8px;
}

.branch4 .branch-inner1 .flower1 {
  width: 25px;
  height: 25px;
  left: -13px;
  top: 15px;
}

.branch4 .tulip1 {
  transform: rotate(10deg);
  width: 20px;
  height: 20px;
  right: -8px;
  bottom: 30px;
}

.branch5 {
  top: 20px;
  right: 0;
  width: 90px;
  height: 30px;
}

.branch5 .branch-inner1 {
  width: 30px;
  height: 30px;
  border-bottom-right-radius: 100% 100%;
  left: 50px;
  top: -5px;
  /* border-width: 4px;	 */
  transform: rotate(-60deg);
}

.branch5 .branch-inner2 {
  width: 40px;
  height: 40px;
  transform: rotate(-50deg);
  border-bottom-left-radius: 100% 80%;
  left: 26px;
  top: -10px;
  /* border-width: 4px;	 */
}

.branch5 .branch-inner1 .leaf1 {
  transform: rotate(-20deg);

  left: 20%;
  bottom: -70%;
  width: 18px;
  height: 18px;
}

.branch5 .branch-inner1 .leaf2 {
  width: 16px;
  height: 16px;
  transform: rotate(-120deg);
  top: 85%;
  left: 40%;
}

.branch5 .branch-inner1 .leaf3 {
  width: 14px;
  height: 14px;
  transform: rotate(-50deg);
  top: 65%;
  left: 80%;
}

.branch5 .branch-inner2 .leaf1 {
  transform: rotate(80deg);
  left: 70%;
  top: 110%;
  width: 18px;
  height: 18px;
}

.branch5 .branch-inner2 .leaf2 {
  width: 16px;
  height: 16px;
  transform: rotate(-140deg);
  left: 43%;
  top: 85%;
}

.branch5 .branch-inner2 .leaf3 {
  width: 14px;
  height: 14px;
  transform: rotate(100deg);
  left: 20%;
  top: 75%;
}

.branch5 .flower.petal5 {
  right: 100%;
  bottom: 100%;
  width: 30px;
  height: 30px;
  margin-right: -15px;
  margin-bottom: -15px;
}

.branch5 .branch-inner1 .heart {
  width: 18px;
  height: 18px;

  top: -20%;
  left: 80%;
  transform: rotate(35deg);
}

.branch5 .branch-inner2 .tulip {
  width: 20px;
  height: 20px;

  top: 5px;
  left: -5px;
  transform: rotate(-15deg);
}

/* .map{
  height: "700px";
  width: "100%";
} */

@media (min-width: 768px) {
  .leaflet-container {
    width: 100%;
    height: 75vh;
  }
}

@media (max-width: 767px) {
  .leaflet-container {
    width: 100%;
    height: 50vh;
  }
}

.modal-backdrop.show {
  z-index: -1;
}

.xl-modal-backshadow.show {
  z-index: 41;
}

.xl-secondary-modal-backshadow.show {
  z-index: 999;
}

.xl-secondary-modal {
  padding-top: 300px;
}

.xl-modal {
  border-radius: 10px;
  width: 100%;
  max-width: calc(100% - 140px);
  max-height: calc(100% - 140px);
  margin: 70px;
}

.xl-modal-body {
  width: 100%;
  max-width: calc(100% - 140px);
}

.xl-modal-header {
  width: 100%;
  max-width: calc(100% - 140px);
}

.container-banner {
  position: relative;
  width: 100%;
  background-color: var(--airco2-green-color) !important;
  /* width: 100%; */
  height: calc(100vh - 40px);
  /* overflow: hidden; */
}

.section-one,
.section-two {
  position: absolute;
  height: 100%;
  width: 65%;
  overflow: hidden;
}

.section-one {
  left: 0;
  top: 0;
  background-color: var(--airco2-green-color) !important;
  -webkit-clip-path: polygon(0 0, 100% 0%, 50% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0%, 50% 100%, 0% 100%);
}

.section-one .content {
  width: 100%;
  height: 100%;
  background-image: url("./images/forest.webp");
  background-size: cover;
  background-position: center;
}

.section-two {
  right: 0;
  top: 0;
  background-color: var(--airco2-green-color) !important;
  -webkit-clip-path: polygon(50% 0, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(50% 0, 100% 0%, 100% 100%, 0% 100%);
}

.section-two .content {
  width: 100%;
  height: 100%;
  background-image: url("./images/bussines.webp");
  background-size: cover;
  background-position: center;
}

.section-one:hover .content,
.section-two:hover .content {
  transform: scale(1.2);
  filter: grayscale(60%);
  transition: ease 2s;
}

.btn-banner {
  background-color: var(--airco2-green-color) !important;
  color: white !important;
}

.btn-banner:hover {
  background-color: var(--airco2-grey-color) !important;
  color: white !important;
}

.btn-banner-o {
  background-color: var(--airco2-orange-color) !important;
  color: white !important;
}

.btn-banner-o:disabled {
  background-color: var(--airco2-grey-color) !important;
}

.btn-banner-o:hover {
  background-color: var(--airco2-grey-color) !important;
  color: white !important;
  text-decoration: none;
}

.btn-intranet:hover {
  background-color: var(--airco2-grey-color) !important;
  color: white !important;
}

.btn-cancel {
  background-color: var(--airco2-grey-color) !important;
  color: white !important;
}

.btn-cancel:hover {
  background-color: var(--airco2-orange-color) !important;
  color: white !important;
}

.text-banner-one {
  position: absolute;
  top: 0;
  margin-top: 35vh;
  margin-left: 15vh;
}

.text-banner-two {
  text-align: right;
  position: absolute;
  top: 0;
  margin-top: 35vh;
  padding-right: 15vh;
}

.text-banner-style {
  text-shadow: 1px 2px 1px #000000;
}

.title-banner {
  font-size: 3rem;
  font-family: "openBold";
}

.info-banner {
  font-size: 1.6rem;
  font-family: "openBold";
}

.info-banner2 {
  font-size: 1.1rem;
  font-family: "openBold";
}

.clean-banner {
  clear: both;
}

@media (max-width: 1024px) {
  .container-banner {
    position: relative;
    width: 100%;
    background-color: var(--airco2-green-color) !important;
    /* width: 100%; */
    height: calc(100vh + 100px);
    /* overflow: hidden; */
  }

  .section-one {
    height: 60%;
    width: 100%;
  }

  .section-two {
    height: 75%;
    width: 100%;
  }

  .section-one {
    left: 0;
    top: 0;
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 90%, 0% 100%);
    clip-path: polygon(0 0, 100% 0%, 100% 90%, 0% 100%);
    z-index: 9;
  }

  .section-two {
    position: absolute;
    left: 0;
    top: 30%;
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }

  .text-banner-one {
    position: absolute;
    text-align: center;
    top: 0;
    margin-top: 25vh;
    margin-left: 0;
  }

  .text-banner-two {
    text-align: center;
    position: absolute;
    top: 0;
    margin-top: 40vh;
    padding-right: 0;
  }

  .title-banner {
    font-size: 3rem;
    /* font-family: 'openLight' */
  }

  .info-banner {
    font-size: 1.2rem;
    /* font-family: 'openLight' */
  }
}

.i-coinscrap {
  min-height: calc(100vh - 104px);
}

.fitwidth {
  width: 1px;
  white-space: nowrap;
}

.ul_contrato {
  list-style: disc;
}

.ul_contrato_num {
  list-style: decimal;
}

.btn-green {
  background-color: var(--airco2-green-color) !important;
  color: white;
  /* margin-left: 12px; */
}

.btn-green:hover {
  background-color: var(--airco2-orange-color) !important;
  color: white;
  text-decoration: none;
  /* margin-left: 12px; */
}

.btn-green:disabled {
  background-color: var(--airco2-grey-color) !important;
}

.text-orange {
  color: var(--airco2-orange-color);
}

a.text-white:hover {
  color: var(--airco2-orange-color) !important;
}

a.border-white:hover {
  border-color: var(--airco2-orange-color) !important;
}

.btn {
  font-family: "openReg" !important;
  font-size: 1rem !important;
  border-radius: 0.375rem !important;
  /* padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  padding-top: 0.3rem !important;
  padding-bottom: 0.3rem !important; */
}

.btn:focus,
.btn:active {
  outline: none;
}

.btn-faqs {
  font-family: "openReg" !important;

  border-radius: 0.375rem !important;
}

.btn-dashboard {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  padding-top: 0.3rem !important;
  padding-bottom: 0.3rem !important;
}

.h1,
.h2,
.h3 {
  font-weight: inherit !important;
}

.link-social:hover {
  color: var(--airco2-orange-color) !important;
  text-decoration: none !important;
}

.link-social:hover svg {
  fill: var(--airco2-orange-color) !important;
}

thead button.ascending::after {
  content: "↑";
  display: inline-block;
  margin-left: 0.25em;
}

thead button.descending::after {
  content: "↑";
  display: inline-block;
  margin-left: 0.25em;
  transform: rotate(180deg);
}

thead button.ascending::after {
  content: "↑";
  display: inline-block;
  margin-left: 0.25em;
}

thead button.orderable::after {
  content: "↑";
  display: inline-block;
  margin-left: 0.25em;
  transform: rotate(180deg);
}

.menu-list {
  font-family: "openReg" !important;
  font-size: 1.1rem;
}

.text-ms {
  font-size: 0.9rem !important;
}

.fullscreen-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
}

.fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* .custom-max-width {
  max-width: 800px;
} */

.icon-tables {
  width: 26px !important;
  height: 26px !important;
  max-width: 26px !important;
}

.marginx_formMontes {
  margin: 0;
}

@media (min-width: 440px) {
  .marginx_formMontes {
    margin: auto;
  }
}

.form-user-data {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.125rem;
  border-width: 0.1px;
  --tw-border-opacity: 0.5;
  background-color: transparent;
}

@media (min-width: 768px) {
  .auth-page {
    width: 66%;
  }
}

.banner-landing-ALIA {
  background-image: url("./images/alia.webp");
  height: 100%;
  background-size: cover;
  background-position: right;
}

.banner-landing-CEO {
  background-image: url("./images/Monte_SanTrocado_Ourense-2.webp");
  height: auto;
  background-size: cover;
  background-position: center;
}

.banner-landing-ANEFHOP {
  background-image: url("./images/Monte_SanTrocado_Ourense-2.webp");
  height: auto;
  background-size: cover;
  background-position: center;
}

.text-landing-title {
  font-size: 50px;
  font-family: "openReg";
  text-shadow: 3px 4px 3px #878787;
  color: #878787;
  margin-bottom: 40px;
  margin-top: 80px;
}

.text-landing-claim {
  font-size: 50px;
  font-family: "openReg";
  text-shadow: 2px 3px 2px #878787;
  color: #878787;
}

.container-landing-eventos {
  height: 100%;
  background-image: url("./images/bosque-landing.jpg");
  background-size: cover;
  /* margin-bottom: 100px; */
}

@media (min-width: 1024px) {
  .w-70 {
    max-width: 70% !important;
  }
}

@media (max-width: 768px) {
  .pc-form {
    margin-top: 20px;
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .custom-flex {
    flex-wrap: wrap;
  }
}

.cnt-txt {
  display: flex;
  flex-wrap: wrap;
}

.vinis {
  height: 187.5px;
  background-image: url("./images/vinisTerrae.jpg");
  background-position: center;
  background-size: cover;
  width: 250px;
}

.number-landing {
  position: absolute;
  font-size: 150px;
  z-index: -1;
  color: var(--airco2-green-color);
  opacity: 0.5;
  font-weight: bolder;
}

.list-case li span {
  font-weight: bold;
  padding-right: 3px;
}

.case-description {
  display: none;
}

.list-pasos {
  list-style: inside;
  margin: 5px;
}

.list-decimal {
  padding-left: 10px;
  list-style: decimal;
}

.form-landing {
  padding: 20px 0;
  /*  */
}

.fondo-landing {
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 10;
}

.landing-cont {
  position: relative;

  background-image: url("./images/landing-fondo.webp");
  opacity: 0.9;

  background-size: cover;
  background-position: center;
}

.p-items {
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.banner-footer-landing {
  height: 50vh;
  background-image: url("./images/landing-footer.webp");
  background-size: cover;
  background-position: bottom;
}

@media (max-width: 500px) {
  .cardRevolucion {
    height: 250px;
    width: 94vw;
  }
}

@media (min-width: 500px) and (max-width: 580px) {
  .cardRevolucion {
    height: 350px;
    width: 94vw;
  }
}

@media (min-width: 580px) and (max-width: 760px) {
  .cardRevolucion {
    height: 390px;
    width: 94vw;
  }
}

@media (min-width: 760px) and (max-width: 830px) {
  .cardRevolucionPrincipal {
    height: 200px;
    width: 300px;
  }

  .cardRevolucion {
    height: 120px;
    width: 175px;
  }
}

@media (min-width: 830px) and (max-width: 992px) {
  .cardRevolucionPrincipal {
    height: 230px;
    width: 330px;
  }

  .cardRevolucion {
    height: 140px;
    width: 200px;
  }
}

@media (min-width: 830px) and (max-width: 940px) {
  .cardRevolucionPrincipal {
    height: 230px;
    width: 330px;
  }

  .cardRevolucion {
    height: 140px;
    width: 200px;
  }
}

@media (min-width: 940px) and (max-width: 1200px) {
  .cardRevolucionPrincipal {
    height: 235px;
    width: 320px;
  }

  .cardRevolucion {
    height: 165px;
    width: 235px;
  }
}

@media (min-width: 1200px) and (max-width: 1350px) {
  .cardRevolucionPrincipal {
    height: 270px;
    width: 370px;
  }

  .cardRevolucion {
    height: 200px;
    width: 300px;
  }
}

@media (min-width: 1350px) {
  .cardRevolucionPrincipal {
    height: 333px;
    width: 466px;
  }

  .cardRevolucion {
    height: 225px;
    width: 325px;
  }
}

.bg-concellos {
  background-image: url("./images/slide-principal-concellos.webp");
  background-size: cover;
  background-position: center;
}

.bg-iconos {
  background-image: url("./images/LoQueNecesitamos.webp");
  max-height: 1200px;
  min-height: 45vh;
  background-size: cover;
  background-position: center;
}

@media (min-width: 1024px) {
  #container-iconos {
    justify-content: space-between;
  }
}

@media (max-width: 959px) {
  .cardConcello {
    min-width: 349px !important;
  }
}

@media (min-width: 960px) {
  .cardConcello {
    min-height: 175px !important;
    min-width: 650px !important;
  }
}

.bloqueImg-img {
  background-image: url("./images/Chico_Bosque.webp");
  min-height: 90vh;
  background-size: cover;
  background-position: center;
}

#concellos-form input {
  border: none;
  border-bottom: 2px solid var(--airco2-orange-color);
}

#concellos-form input[type="checkbox"] {
  border: 2px solid var(--airco2-orange-color);
  border-radius: 5px;
  padding: 5px;
}

#concellos-form input:focus {
  outline: none !important;
  border: 1px solid #f39d7c;
  box-shadow: 0 0 0 #f39d7c;
}

.font-extraBold {
  font-weight: 900;
}

.text-subtitle-bloques {
  font-size: 1.88rem;
}

@media (min-width: 1024px) {
  .bloqueBanner-concellos {
    padding-top: 300px !important;
  }

  .text-airco2-title-concellos {
    font-size: 3.5rem !important;
  }

  .text-airco2-subtitle-concellos {
    font-size: 1.88rem !important;
  }

  .text-airco2-titles-bloques {
    font-size: 2.2rem !important;
  }

  .text-subtitle-bloques {
    font-size: 2.5rem !important;
  }

  .title-ecoCircular {
    padding-top: 1.5rem !important;
  }

  .gap-btn-banner {
    gap: 10rem;
  }
}

@media (min-width: 768px) {
  #txtJuntos {
    padding-left: 1.2rem !important;
    padding-right: 1.2rem !important;
  }

  #txtImgJuntos {
    padding: 1.2rem !important;
  }
}

.box-organizations {
  display: flex;
  flex-direction: column;

  background-color: white;
  border: 2px solid white;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 4px;
  /* cursor: pointer; */
  /*height: 300px;*/
  position: relative;
  width: 280px;
}

.box-calculation {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 2px solid var(--airco2-green-color);
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 4px;
  position: relative;
  width: 200px;
}

.box-calculations {
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 4px;

  /*
  width: 90vw; */
}

@media (min-width: 768px) {
  .form-organization label {
    width: 30%;
  }

  .form-organization input {
    width: 70%;
  }

  .form-organization select {
    width: 70%;
  }

  .form-organization {
    margin-right: 15%;
    margin-left: 15%;
    margin-top: 50px;
  }

  /* .sedes {
    width: 70%;
  } */
}

.accordion-button:not(.collapsed) {
  color: var(--airco2-grey-color) !important;
  background-color: var(--airco2-green-color) !important;
  opacity: 0.8;
}

.tutorials-accordion .accordion-button:not(.collapsed) {
  color: var(--airco2-grey-color) !important;
  background-color: transparent;
  opacity: 0.8;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23878787'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23878787'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:focus {
  border-color: var(--airco2-green-color);

  box-shadow: 0 0 0 0.12rem var(--airco2-green-color);
}

.tutorials-accordion .accordion-button:focus {
  border-color: none;
  box-shadow: none;
}

.accordion-button {
  color: var(--airco2-grey-color) !important;
  font-weight: 600;
}

.tutorials-accordion .accordion-button {
  color: nonce;
  font-weight: 600;
}

.custom-width-md {
  width: 100%;
}

.custom-width-px {
  width: 100%;
}

.custom-input-style {
  width: 100%;
  border-radius: 4px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  border-color: hsl(0, 0%, 80%) !important;
}

.summaryData {
  display: flex;
  justify-content: space-around;
  padding: 3;
}

.grid-report-hightlights {
  margin: 20px auto;
  width: 100%;
  /* height:400px; */
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-row: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.grid-report-nodata {
  margin: 20px auto;
  width: 100%;
  /* height:400px; */
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-row: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.grid-report-hightlights img {
  max-width: 90px;
  max-height: 90px;
}

/* Portrait tablets and small desktops */
@media (max-width: 768px) {
  .grid-report-nodata {
    display: grid;
    grid-template-columns: auto;
    grid-row: auto auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .grid-report-hightlights {
    margin: 20px auto;
    width: 100%;
    background-color: #fff;
    display: grid;
    grid-template-columns: auto;
    grid-row: auto auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}

.grid-report-nodata img {
  max-width: 150px;
  max-height: 150px;
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) {
  .grid-report-header {
    display: grid;
    grid-template-columns: 1.5fr 3fr 1.5fr;
    grid-row: auto auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}

.square-grid-report-footprint-total {
  margin: 20px auto;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-row: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.square-grid-report-footprint-total a:hover {
  color: var(--airco2-orange-color);
}

.square-grid-report-footprint {
  margin: 20px auto;
  /* width:400px;
  height:400px; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.square-grid-report-footprint-emissions {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

@media screen and (max-width: 540px) {
  .square-grid-report-footprint-emissions {
    grid-template-columns: 1fr;
  }
}

.box-report-footprint-emissions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-report-footprint {
  background-color: white;

  /* border-radius:10px; */
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-report-footprint:hover {
  background-color: #56596e;
  cursor: pointer;
}

.box-report-footprint.no-hover {
  background-color: white;
  cursor: default;
}

.box-report-footprint:hover svg {
  fill: var(--airco2-green-color);
}

.box-report-footprint:hover div {
  color: var(--airco2-green-color);
}

.box-report-footprint.no-hover svg {
  fill: #333;
}

.box-report-footprint.no-hover div {
  color: #333;
}

.footprint-menu li {
  width: 135px;
  text-align: center;
  max-height: 70px;
}

.footprint-menu li:first-child {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.footprint-menu li:last-child {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}

.trim-text {
  width: 230px;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1;
}

@media (min-width: 450px) and (max-width: 767px) {
  .sello {
    width: 50%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
  }
}

.restricted {
  content: "";
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(135, 135, 135, 0.5);
  z-index: 1;
  filter: blur(1px);
}

.sliderBtn {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.sliderBtn button {
  width: 100%;
}

/* .trim-text:hover {
  width: auto;
  white-space: initial;
  overflow:visible;
  cursor: pointer;
} */

@media (max-width: 809px) {
  .excelImporterDiv {
    display: none;
  }
}

.circulo1 {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  color: #facc15;
  border: 2px solid #facc15;
  border-bottom-width: 65px;
  gap: 25px;
  background-color: rgba(250, 204, 21, 0.15);

  display: absolute;
}

.circulo3 {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  color: #fb923c;
  border: 2px solid #fb923c;
  border-bottom-width: 65px;
  gap: 25px;
  background-color: rgba(251, 146, 60, 0.15);
  display: absolute;
}

.circulo2 {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  color: #6366f1;
  border: 2px solid #6366f1;
  border-bottom-width: 65px;
  gap: 25px;
  background-color: rgba(99, 102, 241, 0.15);

  display: absolute;
}

.circuloLeyenda {
  width: 95%;
  border-radius: 2%;
  border: 1px solid;
  color: "grey";
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 10px;
}

.tooltip-grande .tooltip-inner {
  max-width: 500px !important;
}

@media (max-width: 951px) {
  .org-justify {
    justify-content: center;
  }
}

.customEditorClassName {
  background-color: white;
  border: 1px solid #ccc;
  min-height: 100px;
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 30px;
  width: 100%;
}

.logo-enisa {
  height: 100px;
}

.images-great-packaging {
  display: none;
}

@media (min-width: 1000px) {
  .images-great-packaging {
    display: flex;
  }
}

.bags-image-great-packaging {
  display: none;
}

.div-great-packaging-top {
  margin-bottom: 0;
}

/* (max-width: 1279px) and */
@media (min-width: 726px) {
  .bags-image-great-packaging {
    display: flex;
    bottom: 0;
  }

  .div-great-packaging-top {
    margin-bottom: 20px;
  }
}

.images-great-packaging-customer1 {
  display: none;
}

@media (min-width: 547px) and (max-width: 860px) {
  .images-great-packaging-customer1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (min-width: 861px) {
  .images-great-packaging-customer1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 50%;
    margin-left: 10px;
  }
}

.images-great-packaging-customer2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

@media (min-width: 547px) {
  .images-great-packaging-customer2 {
    display: none;
  }
}

.images-great-packaging {
  display: none;
}

@media (min-width: 1000px) {
  .images-great-packaging {
    display: flex;
  }
}

.bags-image-great-packaging {
  display: none;
}

.div-great-packaging-top {
  margin-bottom: 0;
}

@media (min-width: 726px) {
  .bags-image-great-packaging {
    display: flex;
    bottom: 0;
  }

  .div-great-packaging-top {
    margin-bottom: 20px;
  }
}

/* .container-great-packaging {
  max-width: max-content;
} */

.gp-table-text {
  font-size: 15px;
}

@media (min-width: 1400px) {
  .container-great-packaging {
    max-width: 70%;
    margin: 0 auto;
  }
  .gp-table-text {
    font-size: 14.25px;
  }
}

@media (min-width: 768px) {
  .gp-container-bolsas {
    flex-direction: row;
  }
}

.images-great-packaging-customer1 {
  display: none;
}

@media (min-width: 547px) and (max-width: 860px) {
  .images-great-packaging-customer1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (min-width: 861px) {
  .images-great-packaging-customer1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 50%;
    margin-left: 10px;
  }
}

.images-great-packaging-customer2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

@media (min-width: 547px) {
  .images-great-packaging-customer2 {
    display: none;
  }
}

/* .pointShadow{
min-width: 350px;
height: 320px;



} */
.pointShadow:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .textCenterMvl {
    text-align: center;
  }

  .alignCenterMvl {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .plansCard {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@media (min-width: 768px) {
  .px-title {
    padding-left: 18%;
    padding-right: 18%;
  }

  .newsMarginR {
    margin-right: 90px;
  }

  .newsMarginL {
    margin-left: 90px;
  }

  /* .px-titleDescription {
    padding-left: 30%;
    padding-right: 30%;
  } */
}

.plansCard {
  max-width: 360px;
}

.mt-80 {
  margin-top: 80px;
}

/* .divDentro{
  width: 320px;


}*/

.text-coolberries-general {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 900;
  color: #222d70 !important;
}

.text-coolberries-secondary {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 900;
  color: #ea5a0b !important;
}

.text-sidebar {
  color: var(--airco2-grey-text);
}

.transparent-text {
  display: flex;
  flex-direction: column;
  text-align: center;
  flex-wrap: wrap;
}

.bordered-text {
  text-align: center;
  font-family: "ITCAvantGardeGoticBold";
  font-size: 2.5rem;
  -webkit-text-stroke: 1.5px white;
  color: transparent;
}
.bordered-text-sm {
  text-align: center;
  font-family: "ITCAvantGardeGoticBold";
  font-size: 1.5rem;
  -webkit-text-stroke: 1.5px white;
  color: transparent;
}

@media (min-width: 768px) {
  .bordered-text {
    font-size: 3.5rem;
  }
  .bordered-text-sm {
    font-size: 2.5rem;
  }
}

@media (min-width: 1280px) {
  .bordered-text {
    font-size: 4.5rem;
  }
  .bordered-text-sm {
    font-size: 3.5rem;
  }
}


.logoAirco2 {
  max-width: 150px;
  height: 147.7px;
  opacity: 0.9;
}

.logoGp {
  max-width: 300px;
  /* opacity: 0.9; */
}

@media (max-width: 768px) {
  .transparent-text h1 {
    font-size: 4rem;
  }

  .logoAirco2 {
    max-width: 100px;
    height: 97.7px;
  }

  .logoGp {
    max-width: 200px;
    opacity: 0.9;
  }
}

.formHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
}

@media (max-width: 500px) {
  .formHeader {
    flex-direction: column-reverse;
    /* align-items: flex-end; */
  }
}

.custom-width-div {
  width: 100%;
  min-width: 20rem;
}

.px-custom {
  padding-right: 6vw;
  padding-left: 6vw;
}

@media (max-width: 768px) {
  .px-custom {
    padding-right: 1vw;
    padding-left: 1vw;
  }

  .text35 {
    font-size: 2.2rem;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  /* text-align: center; */
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.success-slide {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border: 1px solid #d3d3d3 !important;
}

.success-slide-img {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}


.swiper-slide .swiper-body {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 1rem;
  /* 4px */
  border-top-right-radius: 1rem;
  /* 4px */
}

.tutorial-menu-position {
  position: sticky;
  top: 64px;
  z-index: 5;
  border-bottom: 1px solid #dedede;
  padding: 5px 0;
  background-color: var(--airco2-neutro-color);

}

@media (min-width: 640px) {
  .tutorial-menu-position {
    border-right: 1px solid #dedede;
  }
}



.table-compensations > tbody > tr:nth-child(4n-0),
.table-compensations > tbody > tr:nth-child(4n-1) {
  background-color: hsl(225 21% 96% / 1);
}

@media (max-width: 639px) {
  .qwerty {
    height: 70px;
    margin-left: 2px;
    margin-right: 2px;
    width: 80px;
  }
}

@media (min-width: 640px) {
  .qwerty {
    height: 55px;
    margin-left: 1px;
    margin-right: 1px;
    width: 80px;
  }
}

/* .grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(105px, 1fr));
  gap: 10px;
  justify-items: center;
}

.grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  border-radius: 8px;
}
 */

.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.grid-item {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  /* ajusta la altura según tus necesidades */
  border-radius: 8px;
  max-width: 105px;
  /* ancho máximo para controlar el crecimiento */
}

.certification-container {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.875rem;
  line-height: 2.25rem;
  color: var(--gp-green-color);
}

.certification-heading {
  display: inline-block;
  padding: 0 20px;
  /* Espacio horizontal */
  background-color: white;
  /* Fondo blanco detrás del texto */
  position: relative;
  z-index: 1;
}
.gp-image{
  max-width: 100%;
  max-height: 300px; 
  object-fit: contain;
}
@media (max-width: 768px) {
  .certification-container::before,
  .certification-container::after {
      display: none;
  }
}

.certification-container::before,
.certification-container::after {
  content: "";
  position: absolute;
  top: 50%;
  width: calc(50% - 150px);
  /* Ancho de las líneas (50px de espacio a los lados) */
  border-top: 2px solid var(--gp-green-color);
  /* Color de la línea */
  z-index: 0;
}

.certification-container::before {
  left: 0;
}

.certification-container::after {
  right: 0;
}

@media screen and (max-width: 768px) {
  .certification-heading {
    padding: 0 10px;
    /* Ajustar el espacio horizontal en dispositivos móviles */
  }

  .certification-container::before,
  .certification-container::after {
    width: calc(50% - 20px);
    /* Ajustar el ancho de las líneas en dispositivos móviles */
  }
}

.border-airco2 {
  border: 1px solid var(--airco2-green-color);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.border-airco2:hover {
  border-color: var(--airco2-orange-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.border-dark-airco2 {
  border: 1px solid var(--airco2-dark-green-color);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.css-my3gbk-control {
  border-radius: 0px !important;
}

.btn-option {
  border: 2px solid var(--airco2-green-color) !important;
}

.btn-option:hover {
  background-color: rgba(141, 212, 189, 0.4);
}

.btn-option-selected {
  background-color: var(--airco2-green-color) !important;
  border: 2px solid var(--airco2-green-color) !important;
  color: white;
}

.simple-table {
  width: 100%;
  border-collapse: collapse;
}

.container-simple-table {
  overflow-x: auto;
}

.simple-table thead {
  background-color: var(--airco2-green-color);
  font-size: 12px;
  color: white;
}

.simple-table tbody {
  font-size: 14px;
  background-color: white;
}

.simple-table tbody tr,
.simple-table thead tr {
  border-bottom: 1px solid #ddd;
}

.simple-table tbody tr td,
.simple-table thead tr th {
  padding: 1rem 0.75rem;
}

.simple-table tbody tr:hover {
  background-color: var(--airco2-neutro-color) !important;
}
.simple-table thead tr:hover {
  background-color: inherit !important;
}
/* .simple-table td{
  white-space: nowrap;
} */

.simple-table td .up {
  color: #d2322d;
}

.simple-table td .down {
  color: #5cb85c;
}

.evolution-info-card {
  width: 100%;
}

.evolution-years-block {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: 1rem;
}

@media screen and (min-width: 1024px) {
  .evolution-info-card {
    width: 50%;
  }

  .evolution-years-block {
    display: flex;
    flex-direction: row;
  }
}

.down::before {
  content: "";
  font-size: 1.2em;
  background-image: url("./images/trending-down.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px;
  padding: 10px;
}

.up::before {
  content: "";
  font-size: 1.2em;
  background-image: url("./images/trending-up.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px;
  padding: 10px;
}

.highlight {
  transition: background-color 0.5s ease;
  animation: blink-animation 1s steps(5, start) infinite;
  background-color: #b2e7d6;
  /* Tono más claro que combina con el color corporativo */
  background-color: --var(--airco2-light-green-color) !important;
}

@keyframes blink-animation {
  50% {
    visibility: hidden;
  }
}

.progress-bar-descarbonization {
  width: 100%;
  background-color: #e0e0e0;

  overflow: hidden;
  position: relative;
}

.progress-bar-fill {
  height: 20px;
  background-color: #9be1af;
  width: 0;
  transition: width 0.4s ease;
}

.progress-bar-text {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 0;
  left: 0;
  height: 100%;
  line-height: 20px;
  font-size: 14px;
  color: #000;
}

.banner-container {
  background-color: #f0f8ff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.banner-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.text-section {
  max-width: 60%;
}

.main-text {
  color: var(--airco2-grey-color);
  margin: 10px 0;
}

.logo {
  height: 80px;
  margin-left: 10px;
}

.imagen-gris {
  filter: grayscale(100%);
}

.swiper-pagination-bullet-active {
  background-color: var(--airco2-green-color) !important;
}

@media (max-width: 767px) {
  .home-introduction-h1 {
    margin-top: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1020px) {
  .home-introduction-h1 {
    width: 350px;
  }
}

@media (min-width: 1021px) {
  .home-introduction-h1 {
    width: 100%;
    max-width: 950px;
  }
}

.clientNumberBlock {
  margin-right: auto;
  margin-left: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 60px;
  gap: 60px;
  text-align: center;
}

@media screen and (min-width: 700px) and (max-width: 1200px) {
  .clientNumberBlock {
    width: calc(100% - 100px);
  }
}

@media screen and (max-width: 700px) {
  .clientsSimpleNumbers {
    align-items: center;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .clientNumberBlock {
    grid-template-columns: repeat(1, 1fr);
    gap: 40px;
    width: calc(100% - 50px);
  }
}

@media (min-width: 768px) {
  .width-33percent {
    width: 33.33%;
  }
  .width-66percent {
    width: 66.66%;
  }
  .client-actions-sticky {
    position: sticky;
    top: 105px;
    height: fit-content;
  }
  .width-40percent {
    width: 40%;
  }
  .width-60percent {
    width: 60%;
  }
}

.card-client-detail:hover {
  cursor: pointer;
  border: 2px solid var(--airco2-green-color);
}

.card-client-detail:hover span {
  color: var(--airco2-green-color);
}

/* barra de scroll*/

/* Estilo de scrollbar para WebKit */
.sidebar-airco2::-webkit-scrollbar {
  width: 10px;
}

.sidebar-airco2::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.sidebar-airco2::-webkit-scrollbar-thumb {
  background-color: var(--airco2-neutro-color);
  border-radius: 5px;
  border: 2px solid #f0f0f0;
}

.sidebar-airco2::-webkit-scrollbar-thumb:hover {
  background-color: var(--airco2-orange-color);
}

/* Estilo de scrollbar para Firefox */
.sidebar-airco2 {
  scrollbar-width: thin;
  scrollbar-color: var(--airco2-neutro-color) white;
}

.search-container {
  display: flex;
  align-self: center;
  align-items: center;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 0px;
  padding: 5px 10px;
  max-width: 200px;
  width: 100%;
}

.search-container input {
  border: none;
  outline: none;
  flex-grow: 1;
  padding: 0;
  background-color: transparent;
}
.search-container input:focus,
.search-container input:active {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.sellos-miteco div {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;

}

@media (min-width: 451px) {
  .sellos-miteco div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .sellos-miteco div div{
    width: 33.33%;
  }
  .sellos-miteco div p{
    width: 66.66%;
  }
}

.airco2-a-tag {
  cursor: pointer;
  color: var(--airco2-grey-text);
}

.airco2-a-tag:hover {
  cursor: pointer;
  color: var(--airco2-green-color);
}

.airco2-a-tag-italic {
  cursor: pointer;
  font-style: italic;
  color: var(--airco2-grey-text);
}

.airco2-a-tag-italic:hover {
  cursor: pointer;
  font-style: italic;
  color: var(--airco2-green-color);
}


.mitecoDocumentationWrapper {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 25px;
  grid-auto-rows: minmax(auto, 505px);
}

.isoDocumentationWrapper {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 25px;
  grid-auto-rows: minmax(auto, calc(100vh - 180px));
  min-height: 505px
}


@media (max-width: 900px) {
  .mitecoDocumentationWrapper {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;

  }
}
.mitecoDocDataWrapper {
  display: grid;
grid-template-rows: 1fr 5fr;
}

/* .rapper3 {
  display: grid;
  grid-template-rows: minmax(100px, 2fr) minmax(50px, 1fr) minmax(100px, 2fr);} */

  .mitecoDocDataDiv {
    display: grid;
    gap: 10px;
  }

  /* Para el caso de 3 elementos */
  .mitecoDocDataDiv > div:nth-child(1) {
    grid-row: 1;
    height: 2fr;
  }

  .mitecoDocDataDiv > div:nth-child(2) {
    grid-row: 2;
    height: 1fr;
  }

  .mitecoDocDataDiv > div:nth-child(3) {
    grid-row: 3;
    height: 2fr;
  }

  /* Para el caso de solo 2 elementos */
  .mitecoDocDataDiv > div:nth-child(2):only-child {
    grid-row: 2;
    height: 3fr;
  }

  .mitecoUploaddedDocsGrid {
    display: grid;
    gap: 10px; /* Espacio entre divs */
  }

  /* En móviles (pantallas pequeñas) - 1 div por línea */
  @media (max-width: 767px) {
    .mitecoUploaddedDocsGrid {
      grid-template-columns: 1fr; /* 1 columna */
    }
  }

  /* En pantallas medianas (tabletas) - 2 divs por línea */
  @media (min-width: 768px) and (max-width: 1023px) {
    .mitecoUploaddedDocsGrid {
      grid-template-columns: 1fr 1fr; /* 2 columnas */
      gap: 25px; /* Espacio entre divs */

    }
  }

  /* En pantallas grandes (desktops) - 3 divs por línea */
  @media (min-width: 1024px) and (max-width: 1439px) {
    .mitecoUploaddedDocsGrid {
      grid-template-columns: 1fr 1fr 1fr; /* 3 columnas */
      gap: 25px; /* Espacio entre divs */

    }
  }

  /* En pantallas extra grandes (pantallas muy grandes) - 4 divs por línea */
  @media (min-width: 1440px) {
    .mitecoUploaddedDocsGrid {
      grid-template-columns: 1fr 1fr 1fr 1fr; /* 4 columnas */
      gap: 25px; /* Espacio entre divs */

    }
  }


  .miteco-final-step {
    display: flex;
    flex-direction: column-reverse;
  }

@media (min-width: 765px) {
  .miteco-final-step {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}


.miteco-download-certification{
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  color: var(--airco2-grey-color);
  margin-top: 60px;
  gap: 1rem;
}

@media (min-width: 765px) {
  .miteco-download-certification{
    grid-template-columns: repeat(3, minmax(0, 1fr));
    padding-right: 50px;
    padding-left: 50px;
  }
}


.card-tutorial:hover{
 border-color: var(--airco2-green-color) !important;
}



.container-concello-pontevedra {
  display: grid;
  grid-template-columns: 1fr;
  /* Default: Full width for both divs */
  gap: 10px;
}


/* Media query for larger screens */
@media (min-width: 768px) {
  .container-concello-pontevedra {
    grid-template-columns: 2fr 1fr;
    /* 1/3 and 2/3 layout */
  }

  .form-link {
    display: none;
  }
}


.fuente-emision-table-select:hover {
  color: var(--airco2-orange-color) !important;
  cursor:pointer;
}


.simple-table-sm {
  width: 100%;
  border-collapse: collapse;
}

.container-simple-table-sm {
  overflow-x: auto;
}

.simple-table-sm thead, .simple-table-sm thead:hover {
  background-color: var(--airco2-green-color);
  font-size: 12px;
  color: white;
}

.simple-table-sm tbody {
  font-size: 12px;
  background-color: white;
}

.simple-table-sm tbody tr,
.simple-table-sm thead tr {
  border-bottom: 1px solid #ddd;
}

.simple-table-sm tbody tr td,
.simple-table-sm thead tr th {
  padding: 0.3rem 0.15rem;
}

.simple-table-sm tbody tr:hover {
  background-color: var(--airco2-neutro-color) !important;
}



.glossary-scroll{
  scroll-margin-top: 120px;
}

.resources-style p{
  display: block !important;
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  unicode-bidi: isolate !important;
}

.resources-style h2{
  display: block !important;
  font-size: 1.5em !important;
  margin-block-start: 0.83em !important;
  margin-block-end: 0.83em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  font-weight: bold !important;
  unicode-bidi: isolate !important;
}

.resources-style h3{
  display: block !important;
  font-size: 1.17em !important;
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  font-weight: bold !important;
  unicode-bidi: isolate !important;
}

.resources-style h4{
  display: block !important;
  margin-block-start: 1.33em !important;
  margin-block-end: 1.33em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  font-weight: bold !important;
  unicode-bidi: isolate !important;
}

.resources-style a{
  color: var(--airco2-green-color)  !important;
}

.resources-style a:hover{
  color: var(--airco2-orange-color) !important;
}

.resources-style ul, .resources-style ol{
  display: block  !important;
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px  !important;
  padding-inline-start: 40px !important;
  unicode-bidi: isolate !important;
}

.resources-style ol{
  list-style-type: decimal !important;
}

.resources-style ul{
  list-style-type: disc !important;
}

.quantity-expense-container {
  display: flex;
  align-items: center;
  border: 0.1px solid #ccc !important;
  transition: border-color 0.3s ease;
  border-color: hsl(0, 0%, 80%);
}

.quantity-expense-container:focus-within {
  border: 0.1px solid var(--airco2-green-color);
  border-color: var(--airco2-green-color);
  box-shadow: 0 0 0 0.05rem var(--airco2-green-color);
}

.quantity-expense-container-dark {
  display: flex;
  align-items: center;
  border: 0.1px solid #64748b !important;
  transition: border-color 0.3s ease;
  border-color: hsl(215, 16%, 47%);
}

.quantity-expense-container-dark:focus-within {
  border: 0.1px solid var(--airco2-green-color) !important;;
  border-color: var(--airco2-green-color);
  box-shadow: 0 0 0 0.05rem var(--airco2-green-color);
}



.quantity-expense-input {
  border: none;
  outline: none;
}

.quantity-expense-input:focus {
  outline: none; /* Removes individual focus outline */
}


.question-child-border {
  position: relative;
  margin-bottom: 25px; 
}

.question-child-border::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 12px; 
  right: 12px; 
  height: 1px; 
  background-color:  var(--airco2-grey-color);
  pointer-events: none; 
}

@keyframes enter {
  0% {
      opacity: 0;
      transform: translateY(10px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

.animate-enter {
  animation: enter 0.3s ease-out forwards;
}

@keyframes leave {
  0% {
      opacity: 1;
      transform: translateY(0);
  }
  100% {
      opacity: 0;
      transform: translateY(10px);
  }
}

.animate-leave {
  animation: leave 0.3s ease-in forwards;
}

.table-cell-long-string {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Muestra "..." al final del texto truncado */
  max-width: 200px;
}
